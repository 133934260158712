import Absolute from 'src/components/ui/Absolute'
import bgDots1 from 'src/images/bg-dots1.svg'
import Container from 'src/components/ui/Container'
import React from 'react'

const StepsContainer = (props) => (
  <>
    <Absolute as='img' right='2vw' src={bgDots1} top={[30, 56, 46]} width={[24, 48]} />
    <Container display='flex' flexDirection='column' py={[48, 48, 96]} {...props} />
  </>
)

export default StepsContainer
