import styled, { keyframes } from 'styled-components'
import { GiShipWheel } from 'react-icons/gi'

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Spinner = styled(GiShipWheel)`
  animation: ${spinAnimation} 1.5s infinite linear;
`

export default Spinner
