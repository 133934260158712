import styled from 'styled-components'

export default styled.button`
  border: none;
  background: transparent;
  font-weight: 900;
  font-size: 24px;
  color: #272727;
  display: flex;
  align-items: center;
  cursor: pointer;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
