import { useStorageState } from 'react-storage-hooks'

export default function useSessionStorageState (...args) {
  let sessionStorage = {}

  if (typeof window !== 'undefined') {
    sessionStorage = window.sessionStorage
  }

  return useStorageState(sessionStorage, ...args)
}
