import styled from 'styled-components'

const SortableOption = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 900;
  color: #303030;
  margin-bottom: 24px;
  cursor: grab;
  position: relative;
  padding-left: 18px;

  ::before,
  ::after {
    position: absolute;
    top: 50%;
    display: block;
    left: 0;
    content: '';
    width: 12px;
    height: 2px;
    background-color: #ff5616;
  }

  ::before {
    transform: translate(0, -2px);
  }

  ::after {
    transform: translate(0, 2px);
  }
`

export default SortableOption
