import styled, { css } from 'styled-components'

const Option = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 900;
  color: #303030;
  margin-bottom: 24px;
  cursor: pointer;

  ::before {
    display: block;
    content: '';
    width: 8px;
    height: 8px;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    margin-right: 12px;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    flex-grow: 0;
    flex-shrink: 0;

    ${({ checked }) =>
      checked &&
      css`
        border-color: #ff5616;
        background-color: #ff5616;
      `}
  }
`

export default Option
