import React from 'react'
import styled from 'styled-components'
import {
  compose,
  system,
  space,
  color,
  typography,
  layout,
  flexbox,
  background,
  border,
  position,
  shadow,
} from 'styled-system'
import css from '@styled-system/css'
import PropTypes from 'prop-types'
import StyledSystemProps from '@styled-system/prop-types'

const Component = ({ as, children, ...props }) => React.createElement(as, props, children)

const sx = (props) => css(props.sx)(props.theme)

const Box = styled(Component)(
  sx,
  compose(
    space,
    color,
    typography,
    layout,
    flexbox,
    background,
    border,
    position,
    shadow,
    system({ transition: true }),
  ),
)

Box.defaultProps = {
  as: 'div',
}

Box.propTypes = {
  ...StyledSystemProps.space,
  ...StyledSystemProps.color,
  ...StyledSystemProps.typography,
  ...StyledSystemProps.layout,
  ...StyledSystemProps.flexbox,
  ...StyledSystemProps.background,
  ...StyledSystemProps.border,
  ...StyledSystemProps.position,
  ...StyledSystemProps.shadow,
  transition: PropTypes.string,
}

export default Box
