import React, { useMemo, forwardRef } from 'react'
import PropTypes from 'prop-types'

import Box from './Box'

const Container = forwardRef(({ fluid, ...props }, ref) => {
  const maxWidth = useMemo(() => {
    if (fluid) {
      return '100%'
    }

    return ['100%', 1280, 1600]
  }, [fluid])

  return <Box ref={ref} px={24} mx='auto' maxWidth={maxWidth} {...props} />
})

Container.propTypes = {
  fluid: PropTypes.bool,
  ...Box.propTypes,
}

export default Container
