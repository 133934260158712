import styled from 'styled-components'

import NavigationButton from './NavigationButton'

export default styled(NavigationButton)`
  :hover svg {
    transform: translate(6px, 0);
  }

  svg {
    transition: transform 0.2s linear;
    margin-left: 12px;
  }
`
