import css from '@styled-system/css'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import StyledSystemProps from '@styled-system/prop-types'
import { border, color, compose, layout, space, system, typography } from 'styled-system'

const Component = ({ as, children, ...props }) => React.createElement(as, props, children)

const sx = (props) => css(props.sx)(props.theme)

const Text = styled(Component)(
  sx,
  compose(space, color, typography, border, layout, system({ textTransform: true, textDecoration: true })),
)

Text.defaultProps = {
  as: 'p',
  color: '#272727',
  fontSize: 16,
  lineHeight: 1.4,
}

Text.propTypes = {
  ...StyledSystemProps.space,
  ...StyledSystemProps.color,
  ...StyledSystemProps.typography,
  ...StyledSystemProps.border,
  textTransform: PropTypes.oneOf(['none', 'capitalize', 'uppercase', 'lowercase', 'initial', 'inherit']),
}

export default Text
