import React from 'react'

import { ProgressBar } from 'src/components/ui'

import { useSteps } from 'src/hooks'

const StepsProgressBar = () => {
  const { currentStep, totalSteps, steps } = useSteps()

  const currentStepIndex = React.useMemo(() => steps.indexOf(currentStep) + 1, [currentStep, steps])

  return <ProgressBar current={currentStepIndex} total={totalSteps} />
}

export default StepsProgressBar
