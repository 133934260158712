import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.GATSBY_API_URL,
})

instance.interceptors.request.use((config) => {
  if (config.url.includes('/vouchers') || typeof window === 'undefined') {
    return config
  }

  const voucher = JSON.parse(sessionStorage.getItem('voucher'))
  const { headers } = config

  return { ...config, headers: { ...headers, 'X-Voucher-Code': voucher } }
})

export default instance
