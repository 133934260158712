import React from 'react'
import styled from 'styled-components'

import { Flex, Text } from './'

const Slider = styled.span`
  position: relative;
  width: 100px;
  height: 2px;
  margin: 0 12px;

  background-color: #c8c8c8;

  ::after {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: ${({ progress }) => `${progress}%`};
    top: 0;
    left: 0;
    background-color: #ff5616;
    transition: width 0.5s linear;
  }
`

const formatNumber = (number) => (number < 10 ? `0${number}` : number)

const ProgressBar = ({ current, total }) => (
  <Flex alignItems='center'>
    <Text as='span' color='#D2D2D2' fontSize={[12, 16, 24]}>
      {formatNumber(current)}
    </Text>
    <Slider progress={(100 / total) * current} />
    <Text as='span' color='#D2D2D2' fontSize={[12, 16, 24]}>
      {formatNumber(total)}
    </Text>
  </Flex>
)

export default ProgressBar
