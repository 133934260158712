import React from 'react'
import { useSteps } from 'src/hooks'
import { Container, Fixed, NextStepButton, PrevStepButton, StepsProgressBar } from 'src/components/ui'

const StepsNavigation = () => {
  const { currentStep } = useSteps()

  if (currentStep === '/conhecendo-o-navio/obrigado') {
    return null
  }

  return (
    <Fixed bg='white' bottom='0' height={[48, 48, 96]} left='0' right='0'>
      <Container alignItems='center' display='flex' height='100%' justifyContent='space-between'>
        <PrevStepButton />
        <StepsProgressBar />
        <NextStepButton />
      </Container>
    </Fixed>
  )
}
export default StepsNavigation
