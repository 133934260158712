import React from 'react'

import Box from './Box'

const Absolute = (props) => <Box {...props} />

Absolute.defaultProps = {
  position: 'absolute',
}

Absolute.propTypes = {
  ...Box.propTypes,
}

export default Absolute
