import styled from 'styled-components'

import Input from './Input'

export default styled.label`
  font-size: 16px;
  color: #303030;
  font-weight: 900;
  line-height: 24px;
  display: flex;
  align-items: center;

  ${Input} {
    flex: 1;
  }
`
