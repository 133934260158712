import { FaArrowRight } from 'react-icons/fa'
import React from 'react'

import { useSteps } from 'src/hooks'
import { Loader, NextButton, Text } from 'src/components/ui'

const NextStepButton = () => {
  const { isLastStep, handleNext } = useSteps()
  const [isLoading, setIsLoading] = React.useState(false)

  const handleClick = async () => {
    setIsLoading(true)
    try {
      await handleNext()
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <NextButton disabled={isLastStep || isLoading} onClick={handleClick}>
      <Text as='span' display={['none', 'inline']} fontSize={[16, 16, 24]}>
        Continuar
      </Text>

      {isLoading ? <Loader /> : <FaArrowRight />}
    </NextButton>
  )
}

export default NextStepButton
