import api from 'src/providers/api'
import React from 'react'

const PersonContext = React.createContext()

export const PersonProvider = (props) => {
  const [person, setPerson] = React.useState(null)

  const fetchPerson = React.useCallback(
    () =>
      api.get('/persons').then(({ data }) => {
        const [year, month, day] = data.birth_date.split('T')[0].split('-')
        const [hour, minute] = data.birth_time.split(':')

        setPerson({ ...data, birth_date: `${year}-${month}-${day}`, birth_time: `${hour}:${minute}` })
      }),
    [],
  )

  React.useEffect(() => {
    fetchPerson()
  }, [fetchPerson])

  return <PersonContext.Provider value={{ person, refetch: fetchPerson }} {...props} />
}

export default function usePerson () {
  const context = React.useContext(PersonContext)

  if (context === undefined) {
    throw new Error('usePerson must be used within a PersonContext')
  }

  return context
}
