import React from 'react'

import Box from './Box'

const Flex = (props) => <Box display='flex' {...props} />

Flex.propTypes = {
  ...Box.propTypes,
}

export default Flex
