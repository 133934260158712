import { navigate } from '@reach/router'
import React from 'react'

const StepsContext = React.createContext()

export const StepsProvider = (props) => {
  const steps = React.useMemo(
    () => [
      '/conhecendo-o-navio',
      '/conhecendo-o-navio/perfil',
      '/conhecendo-o-navio/faltas/extroversao-e-introversao',
      '/conhecendo-o-navio/faltas/criativo-objetivo',
      '/conhecendo-o-navio/faltas/zelo-e-descuido',
      '/conhecendo-o-navio/faltas/sensibilidade-e-estabilidade-emocional',
      '/conhecendo-o-navio/faltas/conformidade-e-assertividade',
      '/conhecendo-o-navio/faltas/selecao-de-habito',
      '/conhecendo-o-navio/faltas/priorizacao-de-habito',
      '/conhecendo-o-navio/faltas/plano-de-acao',
      '/conhecendo-o-navio/virtudes/extroversao-e-introversao',
      '/conhecendo-o-navio/virtudes/criativo-objetivo',
      '/conhecendo-o-navio/virtudes/zelo-e-descuido',
      '/conhecendo-o-navio/virtudes/sensibilidade-e-estabilidade-emocional',
      '/conhecendo-o-navio/virtudes/conformidade-e-assertividade',
      '/conhecendo-o-navio/virtudes/selecao-de-habito',
      '/conhecendo-o-navio/virtudes/priorizacao-de-habito',
      '/conhecendo-o-navio/virtudes/plano-de-acao',
      '/conhecendo-o-navio/confirmacao-email',
      '/conhecendo-o-navio/obrigado',
    ],
    [],
  )

  const [prevAction, setPrevAction] = React.useState(null)
  const [nextAction, setNextAction] = React.useState(null)

  const [currentStep, setCurrentStep] = React.useState(() => {
    try {
      const item = localStorage.getItem('currentStep')

      return item ? JSON.parse(item) : steps[0]
    } catch (error) {
      return steps[0]
    }
  })
  const totalSteps = React.useMemo(() => steps.length, [steps])
  const isFirstStep = React.useMemo(() => steps.indexOf(currentStep) === 0, [steps, currentStep])
  const isLastStep = React.useMemo(() => steps.indexOf(currentStep) === steps.length - 1, [steps, currentStep])

  const handlePrev = React.useCallback(async () => {
    try {
      if (prevAction) {
        await prevAction()
      }

      const stepIndex = steps.indexOf(currentStep)

      setCurrentStep(steps[stepIndex - 1])
    } catch (err) {
      console.error(err)
    }
  }, [currentStep, steps, prevAction])

  const handleNext = React.useCallback(async () => {
    try {
      if (nextAction) {
        await nextAction()
      }

      const stepIndex = steps.indexOf(currentStep)

      setCurrentStep(steps[stepIndex + 1])
    } catch (err) {
      console.error(err)
    }
  }, [currentStep, steps, nextAction])

  React.useEffect(() => {
    navigate(currentStep)
    localStorage.setItem('currentStep', JSON.stringify(currentStep))
  }, [currentStep])

  return (
    <StepsContext.Provider
      value={{
        currentStep,
        handleNext,
        handlePrev,
        isFirstStep,
        isLastStep,
        setNextAction,
        setPrevAction,
        steps,
        totalSteps,
      }}
      {...props}
    />
  )
}

export default function useSteps () {
  const context = React.useContext(StepsContext)

  if (context === undefined) {
    throw new Error('useSteps must be used within a StepsProvider')
  }

  return context
}
