import React from 'react'

import Box from './Box'

const Fixed = (props) => <Box {...props} />

Fixed.defaultProps = {
  position: 'fixed',
}

Fixed.propTypes = {
  ...Box.propTypes,
}

export default Fixed
