import React from 'react'
import { FaArrowLeft } from 'react-icons/fa'

import { PrevButton, Text } from 'src/components/ui'
import { useSteps } from 'src/hooks'

const PrevStepButton = () => {
  const { isFirstStep, handlePrev } = useSteps()

  const handleClick = async () => {
    await handlePrev()
  }

  return (
    <PrevButton disabled={isFirstStep} onClick={handleClick}>
      <FaArrowLeft />
      <Text as='span' display={['none', 'inline']} fontSize={[16, 16, 24]}>
        Voltar
      </Text>
    </PrevButton>
  )
}

export default PrevStepButton
