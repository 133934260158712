import styled from 'styled-components'

export default styled.input`
  margin-left: 12px;
  border: none;
  border-bottom: 1px solid #d2d2d2;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  color: #303030;
`
