import styled from 'styled-components'
import { space } from 'styled-system'
import StyledSystemProps from '@styled-system/prop-types'

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  box-shadow: 30px 30px 30px rgba(0, 0, 0, 0.1);

  ${space}

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

VideoWrapper.propTypes = {
  ...StyledSystemProps.space,
}

export default VideoWrapper
